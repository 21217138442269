import React, { Component }       from 'react';
import './assets/scss/App.scss';
import { BrowserRouter, Route, Routes }   from 'react-router-dom';
import Header                     from './layout/Header';
import Collection                 from './components/Collection';
import Image                      from './components/Image';
import Carousel                   from './components/Carousel';
import TrendingImage                   from './components/trendingImage';
import WhatsnewImage                   from './components/whatsnewImage';
import Home                       from './pages/Home';
import About                      from './pages/About';
import { Button }                 from 'reactstrap';
import LoginForm from './components/LoginForm';


class App extends Component {

  logoutBtn() {
    // console.log("Logout pressed.");
    localStorage.clear();
    window.location.reload();
  }

  render() {

    if (localStorage.getItem('isLogined') === "true") {
      // console.log(localStorage.getItem("isLogined"))
      return (
        <BrowserRouter>
          <div className="App">
            <Header />
            <Button style={{float: "right"}} color="secondary" onClick={this.logoutBtn.bind(this)}>Logout</Button>
            <Routes>
              <Route path="/" >
                <Route index element = { <Collection/> } />
                <Route path="/home" element = { <Home/> } />
                <Route path="/images" element = { <Image/> } />
                <Route path="/carousel" element = { <Carousel/> } />
                <Route path="/trending" element = { <TrendingImage/> } />
                <Route path="/whatsnew" element = { <WhatsnewImage/> } />
                <Route path="/about" element = { <About/> } />
                <Route path="*" element = { <Home/> } />
              </Route>
            </Routes>

{/* Code before updating the packages */}
            {/* <Routes>
              <Route path="/home" component={ Home } />
              <Route exact path="/" render={prop => (
                <React.Fragment>
                  <Collection />
                </React.Fragment>
              )} />
              <Route path="/images" element = { <Image/> } />
              <Route path="/carousel" element = { <Carousel/> } />
              <Route path="/trending" element = { <TrendingImage/> } />
              <Route path="/whatsnew" element = { <WhatsnewImage/> } />
              <Route path="/about" element = { <About/> } />
            </Routes> */}
{/* End of code before updating the packages */}

          </div>
        </BrowserRouter>
      );
    }
    else {
      // console.log(localStorage.getItem("isLogined") + " else")
      return (
        <div>
          <LoginForm />
        </div>
      )
    }
  }
}

export default App;
