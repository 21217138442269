import axios from 'axios';
import { loginAPIurl } from "../app-config";
import Toast from 'light-toast';

export function doAPILogin(data) {
    // console.log("Got some data" + data.username + data.password);
    try {
        axios.post(loginAPIurl, data,).then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
            // console.log(response.data.access_token);
            Toast.success("Login successfully.", 3000);
            localStorage.setItem("isLogined", "true");
            localStorage.setItem("access_token", response.data.access_token);
            // console.log(localStorage.getItem("isLogined"));
            window.location.reload();
        } else {
            Toast.fail("Login failed.", 1500);
        }
    })
    .catch(error => {
        Toast.fail("Invalid username or password");
    });
    } catch (error) {
        console.log("Failed to login error")
    }
}

export function loginValidation(data) {
    if (data.username.length < 8 || data.username.length > 20) {
        Toast.fail("Username must be between 8 to 20 character long", 1500);
        return false
    }
    if (data.password.length < 8 || data.password.length > 20) {
        Toast.fail("Password must be between 8 to 20 character long", 1500);
        return false
    }
    return true
}


