import React, { Component } from 'react';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, FormGroup, Label, Input } from 'reactstrap';
import { getCarousel } from "./services/CarouselService";
import { carouselAPIurl, fileApiKaavya } from './app-config';
import Loader from './utils/loader';
import NetworkAlert from './utils/NetworkAlert';
import Toast from 'light-toast';
import uploadinggif from '../assets/gif/isuploading.webp';
import { checkIfLogined } from './services/AppService';

class Carousel extends Component {
    state = {
        carousels: [],
        newCarouselData: {
          name: '',
          title: '',
          alignment: 'center',
          status: 'true',
          imageUrl: null
        },
        editCarouselData: {
          id: '',
          name: '',
          title: '',
          alignment: '',
          status: '',
          imageUrl: null,
          oldImageUrl: ''
        },
        newCarouselModal: false,
        editCarouselModal: false,
        loading: true,
        buttonDisabled: false,
        errors: false,
        showUploading: false,
        editImageExists: false
      }

      async componentWillMount() {
        const { data: carousels } = await getCarousel();
        // console.log(carousels);
        this.setState({
          carousels,
          loading: false
        })
      }

      toggleNewCarouselModal() {
        this.setState({
          newCarouselModal: !this.state.newCarouselModal
        });
      }
    
      toggleEditCarouselModal() {
        this.setState({
          editCarouselModal: !this.state.editCarouselModal
        });
      }

      validateCarouselForm(passedCarouselData) {

        if(!passedCarouselData.name) {
          // console.log("name")
          return false
        }

        if (passedCarouselData.status === "") {
          // console.log("status" + passedCarouselData.status)
          return false
        }

        if(!passedCarouselData.imageUrl) {
          // console.log("imageUrl" + passedCarouselData.imageUrl)
          return false
        }
        
        return true
      }

      addCarousel() {
        try {
          checkIfLogined();
          if (this.validateCarouselForm(this.state.newCarouselData)) {
            this.setState({ 
              buttonDisabled: true,
              showUploading: true
            });
            const formData = new FormData();
            formData.append("name", this.state.newCarouselData.name);
            formData.append("title", this.state.newCarouselData.title);
            formData.append("alignment", this.state.newCarouselData.alignment);
            formData.append("status", this.state.newCarouselData.status)
            formData.append("Files", this.state.newCarouselData.imageUrl);
            // console.log(this.state.newCarouselData)
            axios.post(carouselAPIurl, formData, {
              headers: {
                "Content-type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
              }
            })
            .then((response) => {
              if (response.status === 200) {
                let { carousels } = this.state;
  
                if (carousels.length > 0) {
                  carousels.push({
                    id: carousels[carousels.length - 1].id + 1,
                    carousel: null,
                    name: this.state.newCarouselData.name,
                    status: this.state.newCarouselData.status
                  });
                } else {
                  window.location.reload();
                }
  
                this.setState({ carousels, newCarouselModal : false, newCarouselData: {
                  name: '',
                  imageUrl: null,
                  status: 'true',
                  buttonDisabled: false,
                  showUploading: false
                } });
                Toast.success("Slider added successfully.", 3000);
              } else {
                this.setState({ 
                  buttonDisabled: false,
                  showUploading: false
                });
                Toast.fail("Slider add failed.", 3000);
              }
            })
            .catch( (e) => {
              this.setState({ 
                buttonDisabled: false,
                showUploading: false
              });
              Toast.fail("Slider add failed.", 3000);
            });
          } else {
            Toast.fail("Form is invalid.", 1000)
          }
        } catch (error) {
          this.setState({ 
            buttonDisabled: false,
            showUploading: false
          });
        }        
      }

      editCarousel(id, name, title, alignment, status, imageUrltoChange){
        // alert("Edit is under construction.");
        let imageUrl = imageUrltoChange.slice(fileApiKaavya.length)
        const oldImageUrl = imageUrl
        // console.log("This is " + id + name + status + imageUrl + " " + title + alignment + " " + oldImageUrl);
        this.setState({
          editCarouselData: { id, name, title, alignment, status, imageUrl, oldImageUrl}, 
            editCarouselModal: ! this.state.editCarouselModal
        });
      }

      updateCarousel() {
        checkIfLogined();
        // console.log(this.state.editCarouselData);
        // console.log(this.state.editCarouselData.imageUrl)
        try {
          if (this.validateCarouselForm(this.state.editCarouselData)) {
            this.setState({ 
              buttonDisabled: true,
              showUploading: true
            });
            // console.log("Visisbility is : " + this.state.editCarouselData.status)
            const formData = new FormData();
            formData.append("id", this.state.editCarouselData.id);
            formData.append("name", this.state.editCarouselData.name);
            formData.append("title", this.state.editCarouselData.title);
            formData.append("alignment", this.state.editCarouselData.alignment);
            formData.append("status", this.state.editCarouselData.status);

            if (this.state.editCarouselData.imageUrl !== this.state.editCarouselData.oldImageUrl) {

              const file = this.state.editCarouselData.imageUrl
              const fileType = file['type'];
              const validImageTypes = ['image/jpeg', 'image/png'];
              if (validImageTypes.includes(fileType)) {
                // invalid file type code goes here.
                formData.append("Files", this.state.editCarouselData.imageUrl);
                formData.append("imageUrl", file['name']);
                // console.log(file['name'])
              } else {
                Toast.fail("This is not image you damit.", 5000)
                return
              }
            } else {
              formData.append("imageUrl", this.state.editCarouselData.oldImageUrl);
            }
            axios.put(carouselAPIurl, formData, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
              }
            })
            .then((response) => {
              if (response.status === 200) {
                this.setState({
                  editCarouselModal: false,
                  editCarouselData: { id: '', name: '', title: '', alignment: '', status: ''},
                  buttonDisabled: false,
                  showUploading: false
                });
                Toast.success("Slider updated successfully.", 3000);
              } else {
                Toast.fail("Slider failed to update.", 2000);
                this.setState({ 
                  buttonDisabled: false,
                  showUploading: false
                });
              }
            })
            .catch( (e) => {
              this.setState({ 
                buttonDisabled: false,
                showUploading: false
              });
            });
          } else {
            Toast.fail("Form is invalid.", 1000)
            this.setState({ 
              buttonDisabled: false,
              showUploading: false
            });
          }
        } catch (error) {
          Toast.fail("Slider failed to update.", 2000);
          this.setState({ 
            buttonDisabled: false,
            showUploading: false
          });
        }
      }

      deleteCarousel(id) {
        try {
          checkIfLogined();
          if (window.confirm("Do you want to delete?")) {
            axios.delete(`${carouselAPIurl}/${id}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
              }
            })
            .then((response) => {
              if (response.status === 200) {
                Toast.success("Carousel deleted successfully.", 1500);
              } else {
                Toast.fail("Carousel delete failed.", 1500);
              }
            })
            .catch( (e) => {
              Toast.fail("Image delete failed.", 1500)
            })
          } 
        } catch (error) {
          Toast.fail("Carousel delete failed.", 1500);          
        }
      }

    render() {
        let count = 0
        let carousels = this.state.carousels.map((carousel) => {
            count = count + 1
            return (
              <tr key={carousel.id}>
                <td>{count}</td>
                <td>{carousel.name}</td>
                <td>{carousel.title}</td>
                <td>{carousel.alignment}</td>
                <td><img src={carousel.imageUrl} alt={carousel.name} className="carousel-image"></img></td>
                <td>{carousel.status ? "Visibile" : "Invisible"}</td>
                <td>
                  <Button color="success" size="sm" className="mr-2" onClick={
                    this.editCarousel.bind(
                      this,
                      carousel.id,
                      carousel.name,
                      carousel.title,
                      carousel.alignment,
                      carousel.status,
                      carousel.imageUrl
                    )}>Edit</Button>
                  <span> </span>
                  <Button color="danger" size="sm" onClick={
                    this.deleteCarousel.bind(
                      this,
                      carousel.id
                    )}>Delete</Button>
                </td>
              </tr>
            )
          });
          if (this.state.loading) {
            return (
              <Loader />
            )
          };
          if (this.state.errors) {
            return (
              <NetworkAlert />
            )
          }
          return (
            <div className="App container">
              <h1>Slider Add Form</h1>
              <Button className="my-3" color="primary" onClick={this.toggleNewCarouselModal.bind(this)}>Add Slider</Button>
      
              {/* Start of Add carousel Model */}
              <Modal isOpen={this.state.newCarouselModal} toggle={this.toggleNewCarouselModal.bind(this)}>
                <ModalHeader toggle={this.toggleNewCarouselModal.bind(this)}>Add Carousel</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="name">Name: *</Label>
                    <Input type="text" name="name" id="name" value={this.state.newCarouselData.name} onChange={(e) => {
                      let { newCarouselData } = this.state;
      
                      newCarouselData.name = e.target.value;

                      this.setState({ newCarouselData });
                    }} placeholder="Name" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="title">Title: *</Label>
                    <Input type="text" name="title" id="title" value={this.state.newCarouselData.title} onChange={(e) => {
                      let { newCarouselData } = this.state;

                      newCarouselData.title = e.target.value;
      
                      this.setState({ newCarouselData });
                    }} placeholder="Title" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="alignment">Alignment: *</Label>
                    <Input type="select" name="select" value={this.state.newCarouselData.alignment} onChange={
                      (e) => {
                        let { newCarouselData } = this.state;

                        if (e.target.value === "center") {
                          newCarouselData.alignment = "center";
                        } else if (e.target.value === "right") {
                          newCarouselData.alignment = "right";
                        } else {
                          newCarouselData.alignment = "left";
                        }

                        this.setState({ newCarouselData });
                      }} id="status">
                      <option value="center">Center</option>
                      <option value="right">Right</option>
                      <option value="left">Left</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="status">Status: *</Label>
                    <Input type="select" name="select" value={this.state.newCarouselData.status} onChange={
                      (e) => {
                        let { newCarouselData } = this.state;
      
                        newCarouselData.status = (e.target.value === "visible") ? true : false ;
                        
                        this.setState({ newCarouselData });
                      }} id="status">
                      <option value="true">Visible</option>
                      <option value="false">Invisible</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Input type="file" name="File" onChange={
                      (e) => {
                        let { newCarouselData } = this.state;

                        newCarouselData.imageUrl = e.target.files[0];

                        this.setState({ newCarouselData });
                      }} id="file"></Input>
                  </FormGroup>
                  
                  <img src={uploadinggif} style={this.state.showUploading ? {} : { display: 'none' }} alt="loading..." />
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" disabled={this.state.buttonDisabled} onClick={this.addCarousel.bind(this)}>Add Carousel</Button>{' '}
                  <Button color="secondary" onClick={this.toggleNewCarouselModal.bind(this)}>Cancel</Button>
                </ModalFooter>
              </Modal>
              {/* End of Add Carousel Model */}
      
              {/* Start of Update Model */}
              <Modal isOpen={this.state.editCarouselModal} toggle={this.toggleEditCarouselModal.bind(this)}>
                <ModalHeader toggle={this.toggleEditCarouselModal.bind(this)}>Edit Carousel</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="name">Name: *</Label>
                    <Input type="text" name="name" id="name" value={this.state.editCarouselData.name} onChange={(e) => {
                      let { editCarouselData } = this.state;
      
                      editCarouselData.name = e.target.value;
      
                      this.setState({ editCarouselData });
                    }} placeholder="Name" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="title">Title: *</Label>
                    <Input type="text" name="title" id="title" value={this.state.editCarouselData.title} onChange={(e) => {
                      let { editCarouselData } = this.state;

                      editCarouselData.title = e.target.value;
      
                      this.setState({ editCarouselData });
                    }} placeholder="Title" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="alignment">Alignment: *</Label>
                    <Input type="select" name="select" value={this.state.editCarouselData.alignment} onChange={
                      (e) => {
                        let { editCarouselData } = this.state;

                        editCarouselData.alignment = e.target.value;

                        this.setState({ editCarouselData });
                      }} id="status">
                      <option value="center">Center</option>
                      <option value="right">Right</option>
                      <option value="left">Left</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="status">Status: *</Label>
                    <Input type="select" name="select" value={this.state.editCarouselData.status} onChange={
                      (e) => {
                        let { editCarouselData } = this.state;
      
                        editCarouselData.status = e.target.value;
                        
                        this.setState({ editCarouselData });
                      }} id="status">
                      <option value="true">Visible</option>
                      <option value="false">Invisible</option>
                    </Input>
                  </FormGroup>

                  {/* <FormGroup>
                    <Button type="text" disabled={ !this.state.editImageExists} name="removeImage" onClick={ this.removeImage.bind(this) }
                       id="removeImage">Remove Image
                    </Button>
                  </FormGroup> */}

                  <FormGroup>
                    <Input type="file" disabled={ this.state.editImageExists } name="File" onChange={
                      (e) => {
                        let { editCarouselData } = this.state;

                        editCarouselData.imageUrl = e.target.files[0];

                        this.setState({ editCarouselData });
                      }} id="file" accept="image/*"></Input>
                  </FormGroup>

                  <img src={uploadinggif} style={this.state.showUploading ? {} : { display: 'none' }} alt="uploading..." />
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" disabled={this.state.buttonDisabled} onClick={this.updateCarousel.bind(this)}>Update Carousel</Button>{' '}
                  <Button color="secondary" onClick={this.toggleEditCarouselModal.bind(this)}>Cancel</Button>
                </ModalFooter>
              </Modal>
              {/* end of Update Model */}
      
              {/* Start of Table */}
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Title</th>
                    <th>Alignment</th>
                    <th>Image</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
        
                <tbody>
                  {carousels}
                </tbody>
              </Table>
              {/* End of Table */}
            </div>
          );
    }
}

export default Carousel;