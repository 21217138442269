import React from 'react';

function Home() {
    return (
        <React.Fragment>
            <h1>Welcome!</h1>
            <p>This is Image Gallery admin panel.</p>
        </React.Fragment>
    )
}

export default Home;