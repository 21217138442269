import React, { Component } from 'react';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, FormGroup, Label, Input } from 'reactstrap';
import { collectionAPIurl, allCollectionAPIurl, fileApiKaavya } from './app-config';
import Loader from './utils/loader';
import Toast from 'light-toast';
import uploadinggif from '../assets/gif/isuploading.webp';
import { checkIfLogined } from './services/AppService';

class Collection extends Component {
    state = {
        collections: [],
        newCollectionData: {
          name: '',
          imageUrl: null,
          status: 'true'
        },
        editCollectionData: {
          id: '',
          name: '',
          status: '',
          imageUrl: null,
          oldImageUrl: ''
        },
        newCollectionModal: false,
        editCollectionModal: false,
        loading: true,
        buttonDisabled: false,
        showUploading: false,
      }

      componentWillMount() {
        checkIfLogined();
        axios.get(allCollectionAPIurl, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
          }
        })
        .then((response) => {
          // console.log(response.data);
          this.setState({
            collections: response.data,
            loading: false
          });
        });
      }
    
      toggleNewCollectionModal() {
        this.setState({
          newCollectionModal: !this.state.newCollectionModal
        });
      }
    
      toggleEditCollectionModal() {
        this.setState({
          editCollectionModal: ! this.state.editCollectionModal
        });
      }

      validateCollectionForm(passedCollectionData) {
        if(!passedCollectionData.name) {
          // console.log("name")
          return false
        }

        if(!passedCollectionData.imageUrl) {
          // console.log("imageUlr" + passedCollectionData.imageUrl)
          return false
        }

        if (!passedCollectionData.status) {
          // console.log("status")
          return false
        }
        
        return true
      }

      addCollection() {
        
        try {
          checkIfLogined();
          if (this.validateCollectionForm(this.state.newCollectionData)) {
            this.setState({ 
              buttonDisabled: true,
              showUploading: true
            });
            const formData = new FormData();
            formData.append("name", this.state.newCollectionData.name);
            formData.append("status", this.state.newCollectionData.status)
            formData.append("Files", this.state.newCollectionData.imageUrl);
            axios.post(collectionAPIurl, formData, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
              }
            })
            .then((response) => {
              // console.log(response.data);
              if (response.status === 200) {
                // console.log("It is 200 status code.");
                let { collections } = this.state;

                if (collections.length > 0) {
                  collections.push({
                    id: collections[collections.length - 1].id + 1,
                    image: null,
                    name: this.state.newCollectionData.name,
                    status: this.state.newCollectionData.status
                  });
                } else {
                  window.location.reload();
                }

                this.setState({ 
                  collections,
                  newCollectionModal : false,
                  buttonDisabled: false,
                  showUploading: false,
                  newCollectionData: {
                    name: '',
                    imageUrl: null,
                    status: 'true',
                  } 
                });
                Toast.success("Collection added successfully.", 3000);
              } else {
                this.setState({ 
                  buttonDisabled: false,
                  showUploading: false
                });
              }
            })
            .catch( (e) => {
              this.setState({ 
                buttonDisabled: false,
                showUploading: false
              });
              // console.log(e)
              }
            );
          } else {
            Toast.fail("Form is invalid.", 1000)
          }
        } catch (error) {
          // console.log(error)
          this.setState({ 
            buttonDisabled: false,
            showUploading: false
          });
        }
      }

      editCollection(id, name, status, imageUrltoChange){
        // alert("Edit is under construction.");
        let imageUrl = imageUrltoChange.slice(fileApiKaavya.length)
        const oldImageUrl = imageUrl
        // console.log("This is " + id + name + status + imageUrl + oldImageUrl);
        this.setState({
          editCollectionData: { id, name, status, imageUrl, oldImageUrl}, 
          editCollectionModal: ! this.state.editCollectionModal
        });
      }

      updateCollection() {
        try {
          checkIfLogined();
          if (this.validateCollectionForm(this.state.editCollectionData)) {
            // console.log("Update Collection")
            this.setState({ buttonDisabled: true });
            let formData = new FormData()
            formData.append("id", this.state.editCollectionData.id)
            formData.append("name", this.state.editCollectionData.name)
            formData.append("status", this.state.editCollectionData.status)

            if (this.state.editCollectionData.imageUrl !== this.state.editCollectionData.oldImageUrl) {

              const file = this.state.editCollectionData.imageUrl
              const fileType = file['type'];
              const validImageTypes = ['image/jpeg', 'image/png'];
              if (validImageTypes.includes(fileType)) {
                // invalid file type code goes here.
                // console.log("It is image.")
                formData.append("Files", this.state.editCollectionData.imageUrl);
                formData.append("imageUrl", file['name']);
                // console.log(file['name'])
              } else {
                Toast.fail("This is not image you damit.", 5000)
                return
              }
            } else {
              formData.append("imageUrl", this.state.editCollectionData.oldImageUrl);
            }
            
            // console.log("Update Collection")
            axios.put(collectionAPIurl, formData, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
              }
            })
            .then((response) => {
              // console.log(response.data)
              if (response.status === 200) {
                this.setState({
                  editCollectionModal: false,
                  editCollectionData: { id: '', name: '', status: false, imageUrl: null},
                  buttonDisabled: false
                });
                Toast.success("Collection Updated Successfully.", 3000)
              } else {
                this.setState({ buttonDisabled: false });
                Toast.fail("Collection Updated Failed.", 2000)
              }
            })
            .catch( (e) => {
              this.setState({ buttonDisabled: false });
            });
          } else {
            Toast.fail("Form is invalid", 1000)
          }
        } catch (error) {
          this.setState({ buttonDisabled: false });
        }
      }

      deleteCollection(id) {
        try {
          checkIfLogined();
          if (window.confirm("Do you want to delete?")) {
            axios.delete(`${collectionAPIurl}/${id}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
              }
            })
            .then((response) => {
              // console.log(response.data)
              if (response.status === 200) {
                Toast.success("Collection deleted successfully.", 1500);
              } else {
                Toast.fail("Collection delete failed.\nPlease delete all images related to colletion.", 1500);
              }
            })
            .catch( (e) => {
              Toast.fail("Collection delete failed.\nPlease delete all images related to colletion.", 1500)
            })
          }
        } catch (error) {
          console.log(error);
          Toast.fail("Carousel delete failed.", 1500);
        }
      }

    render() {
        let count = 0
        let collections = this.state.collections.map((collection) => {
            count = count + 1
            return (
              <tr key={collection.id}>
                <td>{count}</td>
                <td>{collection.name}</td>
                <td><img src={collection.imageUrl} alt={collection.name} className="product-image"></img></td>
                <td>{collection.status ? "True" : "False"}</td>
                <td>
                  <Button color="success" size="sm" className="mr-2" onClick={
                    this.editCollection.bind(
                      this, 
                      collection.id, 
                      collection.name,
                      collection.status,
                      collection.imageUrl
                    )}>Edit</Button>
                  <Button color="danger" size="sm" onClick={
                    this.deleteCollection.bind(
                      this,
                      collection.id
                    )}>Delete</Button>
                </td>
              </tr>
            )
          });
          if (this.state.loading) {
            return (
              <Loader />
            )
          };
          return (
            <div className="App container">
                <h1>Collection Add Form</h1>
                <Button className="my-3" color="primary" onClick={this.toggleNewCollectionModal.bind(this)}>Add Collection</Button>
        
                {/* Start of Add Collection Model */}
                <Modal isOpen={this.state.newCollectionModal} toggle={this.toggleNewCollectionModal.bind(this)}>
                  <ModalHeader toggle={this.toggleNewCollectionModal.bind(this)}>Add Collection</ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <Label for="name">Name: *</Label>
                      <Input type="text" name="name" id="name" value={this.state.newCollectionData.name} onChange={(e) => {
                        let { newCollectionData } = this.state;
        
                        newCollectionData.name = e.target.value;
        
                        this.setState({ newCollectionData });
                      }} placeholder="Name" />
                    </FormGroup>
                    <FormGroup>
                      <Label for="status">Status: *</Label>
                      <Input type="select" name="select" value={this.state.newCollectionData.status} onChange={
                        (e) => {
                          let { newCollectionData } = this.state;
        
                          newCollectionData.status = e.target.value;

                          this.setState({ newCollectionData });
                        }} id="status">
                        <option value="true">Visible</option>
                        <option value="false">Invisible</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Input type="file" name="File" onChange={
                        (e) => {
                          let { newCollectionData } = this.state;

                          newCollectionData.imageUrl = e.target.files[0];

                          this.setState({ newCollectionData });
                        }} id="file"></Input>
                    </FormGroup>
                    
                    <img src={uploadinggif} style={this.state.showUploading ? {} : { display: 'none' }} alt="loading..." />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" disabled={this.state.buttonDisabled} onClick={this.addCollection.bind(this)}>Add Collection</Button>{' '}
                    <Button color="secondary" onClick={this.toggleNewCollectionModal.bind(this)}>Cancel</Button>
                  </ModalFooter>
                </Modal>
                {/* End of Add Collection Model */}
        
                {/* Start of Update Model */}
                <Modal isOpen={this.state.editCollectionModal} toggle={this.toggleEditCollectionModal.bind(this)}>
                  <ModalHeader toggle={this.toggleEditCollectionModal.bind(this)}>Edit Collection</ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <Label for="name">Name: *</Label>
                      <Input type="text" name="name" id="name" value={this.state.editCollectionData.name} onChange={(e) => {
                        let { editCollectionData } = this.state;
        
                        editCollectionData.name = e.target.value;
        
                        this.setState({ editCollectionData });
                      }} placeholder="Name" />
                    </FormGroup>
                    <FormGroup>
                      <Label for="status">Status: *</Label>
                      <Input type="select" name="select" value={this.state.editCollectionData.status} onChange={
                        (e) => {
                          let { editCollectionData } = this.state;
        
                          editCollectionData.status = e.target.value;
                          
                          this.setState({ editCollectionData });
                        }} id="status">
                        <option value="true">Visible</option>
                        <option value="false">Invisible</option>
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Input type="file" name="File" onChange={
                        (e) => {
                          let { editCollectionData } = this.state;

                          editCollectionData.imageUrl = e.target.files[0];

                          this.setState({ editCollectionData });
                        }} id="file" accept="image/*"></Input>
                    </FormGroup>

                    <img src={uploadinggif} style={this.state.showUploading ? {} : { display: 'none' }} alt="loading..." />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" disabled={this.state.buttonDisabled} onClick={this.updateCollection.bind(this)}>Update Collection</Button>{' '}
                    <Button color="secondary" onClick={this.toggleEditCollectionModal.bind(this)}>Cancel</Button>
                  </ModalFooter>
                </Modal>
                {/* end of Update Model */}
        
                {/* Start of Table */}
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Image</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    {collections}
                  </tbody>
                </Table>
                {/* End of Table */}
              </div>
            );
    }
}

export default Collection;