import axios from 'axios';
import { whatsNewImageAPIurl } from "../app-config";
import Toast from 'light-toast';

export function deleteWhatsNewImage(id) {
    try {
      if (window.confirm("Do you want to delete?")) {
        axios.delete(`${whatsNewImageAPIurl}/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
          }
        })
        .then((response) => {
          // console.log(response.data)
          if (response.status === 200) {
            Toast.success("Image deleted successfully.", 1500);
          } else {
            Toast.success("Image delete failed.", 1500);
          }
        })
        .catch( (e) => {
          Toast.fail("Image delete failed.", 1500)
        })
      } 
    } catch (error) {
      console.log(error);
      Toast.success("Carousel delete failed.", 1500);
    }
}

export function validateWhatsNewImageForm(passedImageData) {

    if(!passedImageData.name) {
      console.log("name")
      return false
    }

    if(!passedImageData.whatsNewId) {
      console.log("whatsNewId")
      return false
    }

    if(!passedImageData.imageUrl) {
      console.log("imageUlr" + passedImageData.imageUrl)
      return false
    }

    if(passedImageData.sale === '') {
      console.log("sale" + passedImageData.sale)
      return false
    }

    if(passedImageData.salePercentage === '') {
      console.log("salePercentage" + passedImageData.salePercentage)
      return false
    }

    if (passedImageData.status === '') {
      console.log("status")
      return false
    }
    
    return true
}