export function checkIfLogined() {
    if (!localStorage.getItem("access_token") || !localStorage.getItem("isLogined")) {
        localStorage.clear()
        window.location.reload();
    }
}

export function clearLocalStorage() {
    localStorage.clear();
    window.location.reload();
}