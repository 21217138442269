import React, { Component } from 'react'

export class SubmitButton extends Component {
      render() {
            return (
                  <div className="submitButton">
                        <button
                              className={this.props.cssClassName}
                              align={this.props.align}
                              disabled={this.props.disabled}
                              onClick={ () => this.props.onClick() }
                        >
                              { this.props.text }
                        </button>
                  </div>
            )
      }
}

export default SubmitButton;
