import React, { Component } from 'react';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, FormGroup, Label, Input } from 'reactstrap';
import { imageAPIurl, allCollectionAPIurl, fileApiKaavya } from './app-config';
import Loader from './utils/loader';
import Toast from 'light-toast';
import uploadinggif from '../assets/gif/isuploading.webp';
import { checkIfLogined } from './services/AppService';

class Image extends Component {
    state = {
        images: [],
        downloadedImages: [],
        collections: [],
        selectedCollection: '1',
        loading: true,
        newImageData: {
          name: '',
          collectionID: '',
          imageUrl: null,
          sale: 'false',
          salePercentage: '0',
          status: 'true',
        },
        editImageData: {
          id: '',
          name: '',
          collectionID: '',
          imageUrl: null,
          sale: '',
          salePercentage: '',
          status: '',
          oldImageUrl: ''
        },
        collectionData: {
          id: '',
          name: ''
        },
        newImageModal: false,
        editImageModal: false,
        buttonDisabled: false,
        showUploading: false,
      }

      componentWillMount() {
        try {
          checkIfLogined();
          axios.get(allCollectionAPIurl, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
            }
          }).then((response) => {
            // console.log(response.data);
            this.setState({
              collections: response.data,
              loading: false
            });
            if (response.data.length > 0) {
              console.log(this.state.newImageData.sale)
              this.setState({
                newImageData: {
                  name: '',
                  collectionID: '' + response.data[0].id,
                  imageUrl: null,
                  sale: 'false',
                  salePercentage: '0',
                  status: 'true',
                }
              })
              console.log(this.state.newImageData.sale);
              this.downloadCollectionImages();
            } else {
              Toast.fail("No collection found.\nPlease add Collection first.", 3000);
            }
          });
        } catch (error) {
          console.log(error)
        }
      }

      downloadCollectionImages() {
        try {
          axios.get(`${imageAPIurl}/collection/${this.state.selectedCollection}`)
          .then((response) => {
            console.log("APi fetched");
            console.log(response.data);
            this.setState({
              images: response.data,
              loading: false
            })
          })
          .catch((error) => {
            console.log("this is error" + error);
          });
        } catch (error) {
          console.log(error)
        }
      }

      toggleNewImageModal() {
        this.setState({
          newImageModal: !this.state.newImageModal
        });
      }
    
      toggleEditImageModal() {
        this.setState({
          editImageModal: !this.state.editImageModal
        });
      }

      validateImageForm(passedImageData) {

        if(!passedImageData.name) {
          console.log("name")
          return false
        }

        if(!passedImageData.collectionID) {
          console.log("collectionID")
          return false
        }

        if(!passedImageData.imageUrl) {
          console.log("imageUlr" + passedImageData.imageUrl)
          return false
        }

        if(passedImageData.sale === '') {
          console.log("sale" + passedImageData.sale)
          return false
        }

        if(passedImageData.salePercentage === '') {
          console.log("salePercentage" + passedImageData.salePercentage)
          return false
        }

        if (passedImageData.status === '') {
          console.log("status")
          return false
        }
        
        return true
      }

      // This method adds collection image
      addCollectionImage() {
        try {
          checkIfLogined();
          if (this.validateImageForm(this.state.newImageData)) {
            this.setState({ 
              buttonDisabled: true,
              showUploading: true
            });
            // console.log(this.state.newImageData);
            const formData = new FormData();
            formData.append("name", this.state.newImageData.name);
            formData.append("collectionId", this.state.newImageData.collectionID);
            formData.append("sale", this.state.newImageData.sale);
            formData.append("salepercentage", this.state.newImageData.salePercentage);
            formData.append("Files", this.state.newImageData.imageUrl);
            formData.append("status", this.state.newImageData.status);
            axios.post(imageAPIurl, formData, {
              headers: {
                "Content-type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
              }
            }).then((response) => {
            // console.log(response.data);
            if (response.status === 200) {
              let { images } = this.state;

              if (images.length > 0) {
                images.push({
                  id: images[images.length - 1].id + 1,
                  image: null,
                  name: this.state.newImageData.name,
                  status: this.state.newImageData.status
                });
              } else {
                window.location.reload();
              }
      
              this.setState({ images, newImageModal : false, newImageData: {
                name: '',
                imageUrl: null,
                sale: 'false',
                salePercentage: '',
                status: 'true',
                },
                buttonDisabled: false,
                showUploading: false
              });
              Toast.success("Image added successfully.", 3000);
            } else {
              this.setState({ 
                buttonDisabled: false,
                showUploading: false
              });
              Toast.fail("Image Add failed.", 2000);
            }
          }).catch( (e) => {
            console.log(e)
            this.setState({ 
              buttonDisabled: false,
              showUploading: false
            });
          });
          } else {
            Toast.fail("Form is invalid.", 1000)
          }
        } catch (error) {
          this.setState({ 
            buttonDisabled: false,
            showUploading: false
          });
        }
      }

      editImage(id, name, collectionID, imageUrltoChange, sale, salePercentage, status){
        // alert("Edit is under construction.");
        let imageUrl = imageUrltoChange.slice(fileApiKaavya.length)
        const oldImageUrl = imageUrl
        // console.log("This is " + id + name + collectionID + imageUrl + sale + salePercentage + status);
        this.setState({
          editImageData: { id, name, collectionID, imageUrl, sale, salePercentage, status, oldImageUrl}, 
          editImageModal: ! this.state.editImageModal
        });
      }

      updateImage() {
        // console.log(this.state.editImageData)
        try {
          checkIfLogined();
          if (this.validateImageForm(this.state.editImageData)) {
            this.setState({ 
              buttonDisabled: true,
              showUploading: true
            });
            const formData = new FormData()
            formData.append("id",  this.state.editImageData.id)
            formData.append("name", this.state.editImageData.name)
            formData.append("collectionId", this.state.editImageData.collectionID)
            formData.append("sale", this.state.editImageData.sale)
            formData.append("salePercentage", this.state.editImageData.salePercentage)
            formData.append("status", this.state.editImageData.status)

            if (this.state.editImageData.imageUrl !== this.state.editImageData.oldImageUrl) {

              const file = this.state.editImageData.imageUrl
              const fileType = file['type'];
              const validImageTypes = ['image/jpeg', 'image/png'];
              if (validImageTypes.includes(fileType)) {
                // invalid file type code goes here.
                console.log("It is image.")
                formData.append("Files", this.state.editImageData.imageUrl);
                formData.append("imageUrl", file['name']);
                console.log(file['name'])
              } else {
                console.log("It is not image.")
                Toast.fail("This is not image you damit.", 5000)
                return
              }
            } else {
              formData.append("imageUrl", this.state.editImageData.oldImageUrl);
            }

            axios.put(imageAPIurl, formData, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
              }
            })
            .then((response) => {
              // console.log(response.data)
              if (response.status === 200) {
                this.setState({
                  editImageModal: false,
                  editImageData: { id: '', name: '', status: false, imageUrl: null},
                  buttonDisabled: false,
                  showUploading: false
                });
                Toast.success("Image Updated Successcully.", 3000)
              } else {
                this.setState({ 
                  buttonDisabled: false,
                  showUploading: false
                });
                Toast.fail("Failed to Update Image.", 1500)
              }
            })
            .catch( (e) => {
              console.log(e)
              this.setState({ 
                buttonDisabled: false,
                showUploading: false
              });
            });
          } else {
            Toast.fail("Form is invalid.", 1000)
          }
        } catch (error) {
          this.setState({ 
            buttonDisabled: false,
            showUploading: false
          });
          Toast.fail("Failed to Update.", 1500)
        }
      }

      deleteImage(id) {
        try {
          checkIfLogined();
          if (window.confirm("Do you want to delete?")) {
            axios.delete(`${imageAPIurl}/${id}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}` 
              }
            })
            .then((response) => {
              // console.log(response.data)
              if (response.status === 200) {
                Toast.success("Image deleted successfully.", 1500);
              } else {
                Toast.success("Image delete failed.", 1500);
              }
              window.location.reload();
            })
            .catch( (e) => {
              Toast.fail("Image delete failed.", 1500)
            })
          } 
        } catch (error) {
          console.log(error);
          Toast.success("Carousel delete failed.", 1500);
        }
      }

    render() {
        let count = 0
        let colletionArray = this.state.collections
        let images = this.state && this.state.images.length > 0 ?
          this.state.images.map((image) => {
            count = count + 1
            let collectionNameArray = colletionArray.filter(item => item.id === image.collectionId)
            let collectionName = ""
            if (collectionNameArray.length > 0) {
              // console.log(collectionNameArray[0].name)
              collectionName = collectionNameArray[0].name
            }
            return (
              <tr className='' key={image.id}>
                <td>{count}</td>
                <td>{image.name}</td>
                <td>{collectionName}</td>
                <td><img src={image.imageUrl} alt={image.name} className="product-image"></img></td>
                <td>{image.sale ? "Yes" : "No"}</td>
                <td>{image.salePercentage}</td>
                <td>{image.status ? "Visible" : "Invisible"}</td>
                <td>
                  <Button color="success" size="sm" className="mr-2" onClick={
                    this.editImage.bind(
                      this, 
                      image.id, 
                      image.name,
                      image.collectionId,
                      image.imageUrl,
                      image.sale,
                      image.salePercentage,
                      image.status,
                    )}>Edit</Button>
                  <span> </span>
                  <Button color="danger" size="sm" onClick={
                    this.deleteImage.bind(
                      this,
                      image.id
                    )}>Delete</Button>
                </td>
              </tr>
            )
          }) : null;
          let collectionsDropdown = this.state.collections.map((collection) => {
            return (
              <option value={collection.id} key={collection.id}>{ collection.name }</option>
            )
          });
          if (this.state.loading) {
            return (
              <Loader />
            )
          };
          return (
            <div className="App container">
              <h1>Image Add Form</h1>
              <FormGroup>
                <Label for="collectionSelect">Collection: *</Label>
                <Input type="select" name="collectionSelect" value={this.state.selectedCollection} onChange={
                  (e) => {
                    let { selectedCollection } = this.state;

                    selectedCollection = e.target.value;

                    this.setState({ selectedCollection });
                  }} id="collectionSelect">
                  { collectionsDropdown }
                </Input>
              </FormGroup>
              <Button className="my-3 mx-2" color="success" onClick={this.downloadCollectionImages.bind(this)}>Load Collection</Button>
              <Button className="my-3" color="primary" onClick={this.toggleNewImageModal.bind(this)}>Add Collection Image</Button>

              {/* Start of Add image Model */}
              <Modal isOpen={this.state.newImageModal} toggle={this.toggleNewImageModal.bind(this)}>
                <ModalHeader toggle={this.toggleNewImageModal.bind(this)}>Add Collection Image</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="name">Name: *</Label>
                    <Input type="text" name="name" id="name" value={this.state.newImageData.name} onChange={(e) => {
                      let { newImageData } = this.state;

                      newImageData.name = e.target.value;

                      this.setState({ newImageData });
                    }} placeholder="Name" required />
                  </FormGroup>
                  <FormGroup>
                    <Label for="collectionId">Collection: *</Label>
                    <Input type="select" name="collectionId" value={this.state.newImageData.collectionID} onChange={
                      (e) => {
                        let { newImageData } = this.state;

                        newImageData.collectionID = e.target.value;

                        this.setState({ newImageData });
                      }} id="collectionId">
                      { collectionsDropdown }
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="sale">Sale: *</Label>
                    <Input type="select" name="sale" value={this.state.newImageData.sale} onChange={
                      (e) => {
                        let { newImageData } = this.state;

                        newImageData.sale = e.target.value;

                        this.setState({ newImageData });
                      }} id="sale">
                      <option value="true">Active</option>
                      <option value="false">In active</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="salePercentage">Sale Percentage</Label>
                    <Input type="number" name="salePercentage" id="salePercentage" value={this.state.newImageData.salePercentage} onChange={(e) => {
                      let { newImageData } = this.state;

                      newImageData.salePercentage = e.target.value;

                      this.setState({ newImageData });
                    }} placeholder="Sale Percentage" min="0" max="100" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="status">Status: *</Label>
                    <Input type="select" name="select" value={this.state.newImageData.status} onChange={
                      (e) => {
                        let { newImageData } = this.state;
      
                        newImageData.status = e.target.value;
                        
                        this.setState({ newImageData });
                      }} id="status">
                      <option value="true">Visible</option>
                      <option value="false">Invisible</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Input type="file" name="File" onChange={
                      (e) => {
                        let { newImageData } = this.state;

                        newImageData.imageUrl = e.target.files[0];

                        this.setState({ newImageData });
                      }} id="file"></Input>
                  </FormGroup>
                  
                  <img src={uploadinggif} style={this.state.showUploading ? {} : { display: 'none' }} alt="loading..." />
                  </ModalBody>
                <ModalFooter>
                  <Button color="primary" disabled={this.state.buttonDisabled} onClick={this.addCollectionImage.bind(this)}>Add</Button>{' '}
                  <Button color="secondary" onClick={this.toggleNewImageModal.bind(this)}>Cancel</Button>
                </ModalFooter>
              </Modal>
              {/* End of Add Image Model */}
      
              {/* Start of Update Model */}
              <Modal isOpen={this.state.editImageModal} toggle={this.toggleEditImageModal.bind(this)}>
                <ModalHeader toggle={this.toggleEditImageModal.bind(this)}>Edit Image</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="name">Name: *</Label>
                    <Input type="text" name="name" id="name" value={this.state.editImageData.name} onChange={(e) => {
                      let { editImageData } = this.state;
      
                      editImageData.name = e.target.value;
      
                      this.setState({ editImageData });
                    }} placeholder="Name" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="sale">Sale: *</Label>
                    <Input type="select" name="sale" value={this.state.editImageData.sale} onChange={
                      (e) => {
                        let { editImageData } = this.state;

                        editImageData.sale = e.target.value;

                        this.setState({ editImageData });
                      }} id="sale">
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="salePercentage">Sale Percentage</Label>
                    <Input type="number" name="salePercentage" id="salePercentage" value={this.state.editImageData.salePercentage} onChange={(e) => {
                      let { editImageData } = this.state;
      
                      editImageData.salePercentage = e.target.value;
      
                      this.setState({ editImageData });
                    }} placeholder="salePercentage" min="0" max="100" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="status">Status: *</Label>
                    <Input type="select" name="select" value={this.state.editImageData.status} onChange={
                      (e) => {
                        let { editImageData } = this.state;
      
                        editImageData.status = e.target.value;
                        
                        this.setState({ editImageData });
                      }} id="status">
                      <option value="true">Visible</option>
                      <option value="false">Invisible</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Input type="file" name="File" onChange={
                      (e) => {
                        let { editImageData } = this.state;

                        editImageData.imageUrl = e.target.files[0];

                        this.setState({ editImageData });
                      }} id="file" accept="image/*"></Input>
                  </FormGroup>
                  
                  <img src={uploadinggif} style={this.state.showUploading ? {} : { display: 'none' }} alt="loading..." />
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" disabled={ this.state.buttonDisabled } onClick={this.updateImage.bind(this)}>Update Image</Button>{' '}
                  <Button color="secondary" onClick={this.toggleEditImageModal.bind(this)}>Cancel</Button>
                </ModalFooter>
              </Modal>
              {/* end of Update Model */}
      
              {/* Start of Table */}
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Collection</th>
                    <th>Image</th>
                    <th>Sale</th>
                    <th>Discount %</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
        
                <tbody>
                  {images}
                </tbody>
              </Table>
              {/* End of Table */}
            </div>
          );
    }
}

export default Image;