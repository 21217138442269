import React, { Component } from 'react';

export class InputField extends Component {
      render() {
            return (
                  <div className="inputField">
                        <input 
                              className={this.props.cssClassName}
                              type={this.props.type}
                              align={this.props.align}
                              placeholder={this.props.placeholder}
                              value={this.props.value}
                              onChange={ (e) => this.props.onChange(e.target.value) }
                        >
                        </input>
                        
                  </div>
            )
      }
}

export default InputField;
