import React from 'react';

function About() {
    return (
        <React.Fragment>
            <h1>About</h1>
            <p>This is the Image Gallery React Admin panel v1.0.0</p>
        </React.Fragment>
    );
}

export default About;