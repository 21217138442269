import axios from 'axios';
import { carouselAPIurl } from "../app-config";
import { checkIfLogined } from './AppService';

export function getCarousel() {
      checkIfLogined();
      return axios.get(`${carouselAPIurl}`);
}

export function addCarousel() {
      
}
