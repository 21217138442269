import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <header style={headerStyle}>
            <h1>Image Gallery Admin Panel.</h1>
            <Link style={linkStyle} to="/home">Home</Link> | 
            <Link style={linkStyle} to="/"> Collection</Link> | 
            <Link style={linkStyle} to="/images"> Image</Link> | 
            <Link style={linkStyle} to="/carousel"> Slider</Link> | 
            <Link style={linkStyle} to="/trending"> Trending</Link> | 
            <Link style={linkStyle} to="/whatsnew"> Whats new</Link> | 
            <Link style={linkStyle} to="/about"> About</Link>
        </header>
    );
}

const headerStyle = {
    background: "#333",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    width: "100%"
}

const linkStyle = {
    color: "#fff",
    textDecoration: "none"
}

export default Header;