// const apiKaavya = "https://localhost:44379"
// const apiKaavya = "https://www.kaavyaboutique.com"
const apiKaavya = "https://service.kaavyaboutique.com"

// export const fileApiKaavya = "https://localhost:44344/"
export const fileApiKaavya = "https://files.kaavyaboutique.com/"

export const loginAPIurl = `${apiKaavya}/api/Auth/login`;

export const imageAPIurl = `${apiKaavya}/api/image`;
export const collectionAPIurl = `${apiKaavya}/api/collection`;
export const allCollectionAPIurl = `${apiKaavya}/api/collection/all`;
export const carouselAPIurl = `${apiKaavya}/api/carousel`;
export const trendingAPIurl = `${apiKaavya}/api/trending`;
export const trendingImageAPIurl = `${apiKaavya}/api/trendingimage`;
export const whatsNewAPIurl = `${apiKaavya}/api/whatsnew`;
export const whatsNewImageAPIurl = `${apiKaavya}/api/whatsnewimage`;