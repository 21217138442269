import React, { Component } from 'react';
import InputField from './utils/InputField';
import SubmitButton from './utils/SubmitButton';
import '../assets/scss/abstract/login.scss';
import { doAPILogin, loginValidation } from './services/LoginService';

class LoginForm extends Component {

      constructor(props) {
            super();
            this.state = {
                  username: '',
                  password: '',
                  buttonDisabled: false,
                  message: ''
            }
      }

    setInputValue(property, val) {
        val = val.trim();
        if (val.length > 20) {
            return;
        }
        this.setState({
            [property]: val
        })
    }

    doLogin() {

        const data = {
            username: this.state.username,
            password: this.state.password
        };

        const result = loginValidation(data);

        if (result) {
            doAPILogin(data);
        }

        // if (this.state.username === 'Kaavya' && this.state.password === 'kaavya@1234') {
        //     localStorage.setItem("isLogined", "true");
        //     console.log(localStorage.getItem("isLogined"));
        //     window.location.reload();
        // } else {
        //     Toast.info("Wrong Username/Password.", 3000);
        // }
        // console.log("Button pressed username ." + this.state.username + " pass " +this.state.password);
    }

      resetForm() {
            this.setState({
                  username: '',
                  password: '',
                  buttonDisabled: false
            })
      }

      render() {
            return (
                  <div className="loginForm">
                        <p className="sign" align="center">Sign in</p>
                        <div className="form1">
                              <InputField
                                    cssClassName='un'
                                    type='text'
                                    align='center'
                                    placeholder='Username'
                                    value={this.state.username ? this.state.username : ''}
                                    onChange={ (val) => this.setInputValue('username', val) }
                              />
                              <InputField
                                    cssClassName='pass'
                                    type='password'
                                    align='center'
                                    placeholder='Password'
                                    value={this.state.password ? this.state.password : ''}
                                    onChange={ (val) => this.setInputValue('password', val) }
                              />
                              <SubmitButton
                                    cssClassName='submit'
                                    align='center'
                                    disabled={this.state.buttonDisabled}
                                    text='Sign in'
                                    onClick={ () => this.doLogin() }
                              />
                        </div>
                  </div>
            )
      }
}

export default LoginForm;
